import Vue from 'vue'
import Router from 'vue-router'
import Report from '@/views/ReportList.vue'
import TestConfigList from '@/views/TestConfigList.vue'
import CalibrationRangesList from '@/views/CalibrationRangesList.vue'
import CalibrationStandardDetail from '@/views/CalibrationStandardDetail.vue'
import ReportDetail from '@/views/ReportListDetail.vue'
import TestConfigDetail from '@/views/TestConfigDetail.vue'
import CalibrationRangesDetail from '@/views/CalibrationRangesDetail.vue'
import DHR from '@/views/DeviceHistoryRecordList.vue'
import LogInForm from '@/components/auth/LogInForm'
import User from '@/views/UserList.vue'
import Company from '@/views/CompanyList.vue'
import CompanyForm from '@/views/CompanyForm.vue'
import AccountEdit from '@/views/UserAccountEdit.vue'
import UserNew from '@/views/UserListForm.vue'
import store from '@/store' // your vuex store
import BatchList from '@/views/BatchList.vue'
import CalibrationList from '@/views/CalibrationList.vue'
import FirmwareList from '@/views/FirmwareList.vue'
import StatisticsList from '@/views/StatisticsList.vue'
import DeviceStatisticsList from '@/views/DeviceStatisticsList.vue'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

// Check if authenticated and admin
const ifAdmin = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (store.getters.hasRole('administrator')) {
      next()
      return
    }
    next('/')
    return
  }
  next('/login')
}

const ifSupport = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (store.getters.hasRole('administrator') || store.getters.hasRole('support')) {
      next()
      return
    }
    next('/')
    return
  }
  next('/login')
}

const ifAuthenticated = (to, from, next) => {
  console.log('to', to)
  if (store.getters.isAuthenticated) {
    if ((store.getters.hasRole('support') || store.getters.hasRole('productionOwner')) && to.path == '/') {
      // Support home is dhr
      next('/dhr')
      return
    }
    next()
    return
  }
  next('/login')
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/user',
      name: 'user',
      beforeEnter: ifAdmin,
      component: User
    },
    {
      path: '/statistics',
      name: 'statistics',
      beforeEnter: ifAdmin,
      component: StatisticsList
    },
    {
      path: '/statistics/:end_serial',
      name: 'device-statistics',
      beforeEnter: ifAdmin,
      component: DeviceStatisticsList,
      props: true
    },
    {
      path: '/company',
      name: 'company',
      beforeEnter: ifAdmin,
      component: Company
    },
    {
      path: '/company-edit/:id',
      name: 'company-edit',
      component: CompanyForm,
      beforeEnter: ifAdmin,
      props: true
    },
    {
      path: '/company/new',
      name: 'company-new',
      component: CompanyForm,
      beforeEnter: ifAdmin,
      props: true
    },
    {
      path: '/firmware',
      name: 'firmware',
      beforeEnter: ifAdmin,
      component: FirmwareList
    },
    {
      path: '/user-edit/:id',
      name: 'user-edit',
      component: UserNew,
      beforeEnter: ifAdmin,
      props: true
    },
    {
      path: '/user/new',
      name: 'user-new',
      component: UserNew,
      beforeEnter: ifAdmin,
      props: true
    },
    {
      path: '/calibration',
      name: 'calibration',
      beforeEnter: ifAdmin,
      component: CalibrationList
    },
    {
      path: '/calibration-standard-detail/:id',
      name: 'calibration-standard-detail',
      beforeEnter: ifAdmin,
      component: CalibrationStandardDetail,
      props: true
    },
    {
      path: '/report/:device_serial?',
      name: 'report',
      beforeEnter: ifAuthenticated,
      component: Report,
      alias: '/',
      props: true
    },
    {
      path: '/test-configs',
      name: 'test-configs',
      beforeEnter: ifAdmin,
      component: TestConfigList,
      alias: '/'
    },
    {
      path: '/test-config-detail/:id',
      name: 'test-config-detail',
      beforeEnter: ifAdmin,
      component: TestConfigDetail,
      props: true
    },
    {
      path: '/calibration-ranges',
      name: 'calibration-ranges',
      beforeEnter: ifAdmin,
      component: CalibrationRangesList,
      alias: '/'
    },
    {
      path: '/calibration-ranges-detail/:id',
      name: 'calibration-ranges-detail',
      beforeEnter: ifAdmin,
      component: CalibrationRangesDetail,
      props: true
    },
    {
      path: '/report-detail/:id',
      name: 'report-detail',
      beforeEnter: (to, from, next) => {
        // First check if user is authenticated
        if (!store.getters.isAuthenticated) {
          next('/login')
          return
        }
        // Check if user admin or support
        if (store.getters.hasRole('administrator') || store.getters.hasRole('support')) {
          next()
        } else {
          // Prevent other users from going from report-detail to report-detail (for example by altering report id)
          if (from.name !== 'report') {
            // refresh is allowed
            next('/report')
          } else {
            next()
          }
        }
      },
      component: ReportDetail,
      props: true
    },
    {
      path: '/dhr/:device_serial?',
      name: 'dhr',
      beforeEnter: ifAuthenticated,
      component: DHR,
      props: true
    },
    {
      path: '/batches',
      name: 'batches',
      beforeEnter: ifSupport,
      component: BatchList
    },
    {
      path: '/login',
      component: LogInForm,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/account/me',
      component: AccountEdit,
      beforeEnter: ifAuthenticated
    }
  ]
})
