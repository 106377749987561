import { REPORT_REQUEST, REPORT_ERROR, REPORT_SUCCESS } from '@/store/actions/reports'
import Vue from 'vue'

const state = {
  status: '',
  reportList: []
}

const getters = {
  getReportList: state => state.reportList
}

const actions = {
  [REPORT_REQUEST]: ({ commit }) => {
    commit(REPORT_REQUEST)
    Vue.axios.get('report').then(resp => {
      commit(REPORT_SUCCESS, resp.data)
    }).catch(() => {
      commit(REPORT_ERROR)
    })
  }
}

const mutations = {
  [REPORT_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [REPORT_SUCCESS]: (state, resp) => {
    state.status = 'success'
    Vue.set(state, 'reportList', resp)
  },
  [REPORT_ERROR]: (state) => {
    state.status = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
