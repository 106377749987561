<template>
   <div class="container">
    <div class="row  justify-content-md-center">
      <div class="col-4">
        <h2>Please log in to continue</h2>
      </div>
    </div>
    <div class="row  justify-content-md-center">
      <div class="col-4">
        <b-alert :show="dismissCountDown"
             dismissible
             :variant="notification.type"
             @dismissed="dismissCountDown=0"
             @dismiss-count-down="countDownChanged">
             {{ notification.message }}
        </b-alert>
        <form class="ui form" @submit.prevent.stop>
          <div class="form-group">
            <label for="email">Email address</label>
            <input type="email" name="email" v-model="email" class="form-control" aria-describedby="emailHelp" placeholder="Enter email" required>
          </div>

          <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control" name="password" v-model="password" placeholder="Password"  autocomplete="password">
          </div>
          <button @click.prevent.stop="login" type="submit" class="btn btn-primary">Login</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST } from '@/store/actions/auth'

export default {
  name: 'LogInForm',
  data () {
    return {
      email: '',
      password: '',
      dismissCountDown: 0,
      dismissSecs: 8,
      notification: {
        message: '',
        type: ''
      }
    }
  },
  methods: {
    login() {
      const { email, password } = this
      this.$store.dispatch(AUTH_REQUEST, { email, password }).then((val) => {
        console.log("Auth success!", val)
        this.$router.push('/').catch((err) => {
          console.log(err)
        })
      }).catch((err) => {
        console.log(err)
        this.notification = {
          message: 'Login failed, user name or password incorrect',
          type: 'danger'
        }
        this.showAlert()
      })
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    }
  }
}
</script>
