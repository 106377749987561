<template>
    <div class="container-fluid">
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <h2>Device Statistics: </h2>
          <h5>{{ end_serial }}</h5>
        </div>
      </div>
      <div>
        <div class="row  justify-content-md-center">
          <div class="col-10">
            <device-statistics-list-table :filter="filter" :end_serial="end_serial"></device-statistics-list-table>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex'
  // import { REPORT_REQUEST } from '@/store/actions/reports'
import DeviceStatisticsListTable from '../components/DeviceStatisticsListTable.vue'
  
  export default {
    data () {
      return {
        name: 'ReportList',
        props: [],
        filter: this.$route.params.device_serial ? this.$route.params.device_serial : "",
        end_serial: this.$route.params.end_serial ? this.$route.params.end_serial : ""
      }
    },
    components: {
      DeviceStatisticsListTable,
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'authStatus', 'hasRole', 'hasSignature', 'getProfile'])
    },
    methods: {},
    created() {
      // this.$store.dispatch(REPORT_REQUEST)
    }
  }
  </script>
  