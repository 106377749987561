<template>
  <div>
    <b-modal id="confirmDelete" ok-title="Yes" ok-variant="danger" cancel-title="No" @ok="deleteReport(idToDelete)" title="Are you sure?">
      <div class="mb-3">
        This will delete the report with ID: {{idToDelete}} Are you sure?
      </div>
    </b-modal>
    <b-table ref="reportTable" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :busy.sync="isBusy"
                    :striped="true"
                    :hover="true"
                    :items="fetchReports"
                    :fields="reportFields"
                    :outlined="true"
                    :small="true"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage">
      <template v-slot:cell(selected)="data" label="selected">
        <b-form-checkbox @click.native.stop @change="clickedCheckbox" v-model="data.item.selected"></b-form-checkbox>
      </template>
      <template v-slot:cell(index)="data" label="test">
        {{data.item.id}}
      </template>
      <template v-slot:cell(end_serial)="data" label="test">
        <router-link :to="{name:'report-detail', params:{id:data.item.id}}">{{data.item.end_serial}}</router-link>
      </template>
      <template v-slot:cell(production_date)="data" label="test">
        {{data.item.production_date | formatDate}}
      </template>
      <template v-slot:cell(operator)="data" label="test">
        {{ data.item.user.username }}
      </template>
      <template v-slot:cell(download_report)="data" label="test">
        <a v-if="data.item.result == 'Pass'" href="#" @click.prevent.stop="downloadReport(data.item.id, 'report', data.item.end_serial)"><octicon name="desktop-download"></octicon></a>
      </template>
      <template v-slot:cell(download_certificate)="data" label="test">
        <a v-if="released && data.item.result == 'Pass' && data.item.revision === 'CFR00101a'"
          href="#" @click.prevent.stop="downloadReport(data.item.id, 'certificate', data.item.end_serial)"><octicon name="desktop-download"></octicon></a>
      </template>
      <template v-slot:cell(delete)="data" label="delete" v-if="hasRole('administrator')">
        <template>
          <a v-b-modal.confirmDelete href="#" @click.prevent.stop="idToDelete = data.item.id"><octicon name="trashcan"></octicon></a>
        </template>
      </template>
    </b-table>
    <div v-if="totalRows > perPage" class="row justify-content-md-center">
      <div class="col-6 mt-3">
        <b-pagination size="md" align="center" :total-rows="totalRows" v-model="currentPage" :per-page="perPage"></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Octicon from 'vue-octicon/components/Octicon.vue'
import 'vue-octicon/icons'
import moment from 'moment'
import { mapGetters } from 'vuex'

Vue.filter('formatDate', function(value) {
  // console.log(value)
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
})
export default {
  props: [
    'device_id',
    'filter',
    'released'
  ],
  components: {
    Octicon
  },
  data () {
    return {
      name: 'report-list-table',
      sortBy: 'id',
      sortDesc: true,
      graphLoaded: false,
      currentPage: 1,
      perPage: 15,
      isBusy: false,
      totalRows: 0,
      idToDelete: null,
      device_released: this.released,
      // reports: [],
      reportFields:
      [
        { key: 'index', label: '#' },
        { key: 'base_serial', sortable: true },
        { key: 'end_serial', sortable: true },
        { key: 'operator', sortable: true },
        { key: 'testType', sortable: true },
        { key: 'revision', sortable: true },
        { key: 'production_date', label: 'Date', sortable: true },
        { key: 'duration', sortable: true },
        { key: 'version', sortable: true },
        { key: 'calibration_standard', sortable: true },
        { key: 'result', sortable: true }
        // (() => {return this.$store.getters.hasRole('manager') || this.$store.getters.hasRole('administrator') ? 'approval' : null})()
        // <% if (me.role == "manager") { %>
        // 'approval',
        // <% } %>
        // <% if (me.role == "support" || me.role == "admin") { %>
        // 'download_certificate',
        // 'download_report'
        // <% } %>
      ]
    }
  },
  created() {
    if (this.hasRole('administrator')) {
      this.reportFields.push({ key: 'delete', label: 'Delete', sortable: false })
    }
    if (this.released) {
      if (this.hasRole('productionOwner') || this.hasRole('administrator') || this.hasRole('support')) {
        this.reportFields.push({ key: 'download_report', label: 'Report', sortable: true })
        this.reportFields.push({ key: 'download_certificate', label: 'Certificate', sortable: false })
      }
    }
  },
  computed: {
    ...mapGetters(['hasRole'])
  },
  methods: {
    fetchReports(ctx) {
      console.log("Current_page", ctx)
      this.isBusy = true
      return this.$http.get(`/report`, {
        params: {
          ...ctx,
          device_id: this.device_id
        }
      }).then(response => {
        console.log(response)
        this.devices = response.data.data
        this.devices.forEach((device) => {
          // Mark released devices
          if ((this.hasRole('productionOwner')) && (!!device.released_by)) {
            device._rowVariant = 'success'
          }

          // Mark Failed devices
          if (device.result == 'Fail') {
            device._rowVariant = 'custom-red'
          }
          // MIGHT BE NEEDED IN THE FUTURE. Format DMU devices. Remove prefix
          // device.end_serial = device.end_serial.replace(/DMU/g, '')
          // device.base_serial = device.base_serial.replace(/CFR/g, '')
        })
        this.totalRows = parseInt(response.data.total)
        this.isBusy = false
        return (this.devices)
      }).catch(error => {
        console.log(error)
        // Here we could override the busy state, setting isBusy to false
        this.isBusy = false
        // Returning an empty array, allows table to correctly handle busy state in case of error
        return []
      })
    },
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    downloadReport(id, type, name) {
      this.$http.get(`/report/${type}/${id}.pdf`, {
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}_${type}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
    deleteReport(reportId) {
      console.log('Deleting report:', reportId)
      this.$http.delete(`report/${reportId}`).then(response => {
        this.$refs.reportTable.refresh()
      }).catch((err) => {
        console.log(err)
      })
    },
    clickedCheckbox(item, index, event) {
      console.log("Checkbox!", item, index)
      console.log("data!", this.reports)
      // window.location = "/report/"+item.id
    }
  }
}
</script>
