<template>
    <div class="container-fluid">
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <h2>Statistics</h2>
        </div>
      </div>
      <div>
        <div class="row justify-content-md-center mb-3">
          <div class="col-1"><label for="filter">Filter</label></div>
          <div class="col-4">
            <b-form-input v-model="filter"
                    type="text"
                    id="filter"
                    placeholder="e.g. DMU"></b-form-input>
          </div>
          <div class="col-5"></div>
        </div>
        <div class="row  justify-content-md-center">
          <div class="col-10">
            <statistics-list-table :filter="filter"></statistics-list-table>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex'
  // import { REPORT_REQUEST } from '@/store/actions/reports'
import StatisticsListTable from '../components/StatisticsListTable.vue'
  
  export default {
    data () {
      return {
        name: 'ReportList',
        props: ['end_serial'],
        filter: this.$route.params.device_serial ? this.$route.params.device_serial : "",
      }
    },
    components: {
        StatisticsListTable,
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'authStatus', 'hasRole', 'hasSignature', 'getProfile'])
    },
    methods: {},
    created() {
      // this.$store.dispatch(REPORT_REQUEST)
    }
  }
  </script>
  