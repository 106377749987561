<template>
 <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Manage Companies</h2>
      </div>
    </div>
    <div v-if="companies">
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <b-table  :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :striped="true"
                    :hover="true"
                    :items="companies"
                    :fields="companyFields"
                    :outlined="true"
                    :small="true">
            <template v-slot:cell(name)="data">
              <router-link :to="{name:'company-edit', params:{id:data.item.id}}">{{data.item.name}}</router-link>
            </template>
            <template v-slot:cell(manufacturer)="data">
              {{data.item.manufacturer ? 'Yes' : 'No'}}
            </template>
            <template v-slot:cell(actions)="data">
              <span class="mr-3">
                <router-link :to="{name:'company-edit', params:{id:data.item.id}}"><octicon name="pencil"></octicon></router-link>
              </span>
            </template>
          </b-table>
          <router-link class="btn btn-primary" to="/company/new"><octicon name="plus"></octicon> Add company</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Octicon from 'vue-octicon/components/Octicon.vue'

export default {
  name: "CompanyList",
  components: {
    Octicon
  },
  data() {
    return {
      companies: [],
      sortBy: 'id',
      sortDesc: false,
      companyFields:
      [
        { key: 'id', label: '#' },
        { key: 'name', sortable: true },
        { key: 'Manufacturer', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'actions' }
      ]
    }
  },
  methods: {
    fetchCompanies() {
      this.$http.get(`/company`).then(response => {
        console.log(response)
        this.companies = response.data
      })
    }
  },
  created() {
    this.fetchCompanies()
  }
}
</script>
