<template>
  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-5">
        <td class="col-1"><h2>Calibration Standard Details</h2></td>
      </div>
    </div>

    <div v-if="calibrationStandardData">
      <div class="row  justify-content-md-center">
        <div class="col-5">
          <table class="table table-sm table-bordered custom-shadow-grey">
            <thead>
              <tr>
                <th scope="col">Calibration Standard Serial</th>
                <td scope="col">{{calibrationStandardData.calibration_serial}}-{{calibrationStandardData.calibration_id | pad(3)}}</td>
              </tr>
              <tr>
                <th scope="col">Target Revision</th>
                <td scope="col">{{calibrationStandardData.target_revision}}</td>
              </tr>
              <tr>
                <th scope="col">Created at</th>
                <td scope="col">{{calibrationStandardData.created_at}}</td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>

    <div class="row  justify-content-md-center">
      <div class="col-5" v-if="paramsLoaded">
          <b-table :small="true" :striped="true"
                          :hover="true"
                          :items="calData"
                          :fields="dataFields">
          </b-table>
      </div>
    </div>

    <div class="row justify-content-md-center">
      <div class="col-2">
        <b-button :block="true" @click="$router.go(-1)">Back</b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      name: 'calibration-standard-detail',
      calData: [],
      calibrationStandardData: {},
      showRaw: false,
      dataFields: ['parameter', 'value'],
      props: ['id'],
      paramsLoaded: false
    }
  },
  name: 'calibration-ranges-detail',
  methods: {
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    fetchStandard(id) {
      this.$http.get(`/calibration-standard/${id}`).then(response => {
        this.calibrationStandardData = response.data
        let i = 0
        for (let item in response.data) {
          console.log(item, response.data[item])
          this.calData[i] = { 'parameter': item, 'value': response.data[item] }
          i++
        }
        this.paramsLoaded = true
        console.log('CAL FETCHED:', response.data)
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  created() {
    this.fetchStandard(this.$route.params.id)
  }
}
</script>
