<template>
  <div class="row justify-content-md-center mt-1 mb-3">
    <div class="col-10" v-if="!show">
      <b-button variant='primary' @click.prevent.stop="show = !show">Help</b-button>
    </div>
    <div class="col-10" v-if="show">
      <b-card class="custom-shadow-grey" header-bg-variant="primary" bg-variant="white" header-text-variant="white" border-variant="primary">
        <strong slot="header">Help</strong>
        Note: some functionalities might not be available depending on your role.<br><br>
        <div v-if="topic === 'batch'">
          <b>Icons</b><br>
          <octicon name="desktop-download"></octicon> = Download button<br><br>
          <b>Devices in a batch</b><br>
          Click 'Show' in the 'Details' column to see a list of devices in a batch.<br><br>

          <b>Production Reports</b><br>
          To download all PASSED production reports for the devices in a batch click the download button in the 'Production Reports' column.
          This will download the most recent end test report for every device in the batch. (Most recent = most recent date before batch creation date)<br><br>

          <b>Certificates</b><br>
          To download certificates for all devices in a batch click the download button in the 'Certificates' column. Note: available for CFR only<br>
          The date in the certificate will be the date of the corresponding end test report<br><br>

          <b>Documents</b><br>
          Add documents (e.g. a batch verification report) to a batch by clicking 'Add'. The devices and documents in a batch can be shown
          by clicking 'Show' in the details column.<br><br>
        </div>
        <div v-if="topic === 'dhr'">
          <b>Icons</b><br>
          <octicon name="desktop-download"></octicon> = Download button<br>
          <octicon name="verified"></octicon> = Device is released<br><br>
          <b>Release</b><br>
          Click 'Release' to release a device after successful production.<br>
          You can only download reports or certificates for a device after it has been released!<br><br>

          <b>Reports</b><br>
          Click 'Show' in the 'Details' column to show all reports for a device.<br>
          You can view a report by clicking on the 'End Serial' column. <br>
          Click the download button in the 'Report' column of a report to download a report.<br><br>

          <b>Certificates</b><br>
          Click 'Show' in the 'Details' column to show all reports for a device.<br>
          Click the download button in the 'Certificates' column of a report to download a certificate.<br>
          You can download certificates for CFR devices only. The date of the report row in which you click the download button will be the date
          that is shown in the certificate.<br><br>

          <b>Documents</b><br>
          You can upload documents for a device by clicking 'Add' in the 'Documents' column. Documents can be viewed and downloaded when you
          click 'Show' in the 'Details' column.<br><br>

          <b-table striped hover :items="dhr_rows" :fields="dhr_table_fields">
          </b-table>
        </div>
        <b-button variant='primary' @click.prevent.stop="show = !show">Close</b-button>
      </b-card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Octicon from 'vue-octicon/components/Octicon.vue'
import 'vue-octicon/icons'
Vue.use(BootstrapVue)

export default {
  props: [
    'topic'
  ],
  components: {
    Octicon
  },
  data () {
    return {
      name: 'help-card',
      show: false,
      dhr_table_fields: [
        { key: 'status', label: 'Row colors show the status of a device:' }
      ],
      dhr_rows: [
        { status: 'Released, most recent test passed.', _rowVariant: "success" },
        { status: 'Not released yet, most recent test passed.', _rowVariant: "white" },
        { status: 'Either released or not released, most recent test failed.', _rowVariant: "custom-red" }
      ]
    }
  },
  created() {

  },
  computed: {
  },
  methods: {
  }
}
</script>
