<template>
  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-5">
        <td class="col-1"><h2>Calibration Ranges</h2></td>
      </div>
    </div>

    <div v-if="calibrationRanges">
      <div class="row  justify-content-md-center">
        <div class="col-5">
          <table class="table table-sm table-bordered custom-shadow-grey">
            <thead>
              <tr>
                <th scope="col">Hardware Revision</th>
                <td scope="col">{{calibrationRanges.revision}}</td>
              </tr>
              <tr>
                <th scope="col">Created at</th>
                <td scope="col">{{calibrationRanges.created_at}}</td>
              </tr>
              <tr>
                <th scope="col">Calibration Ranges Version</th>
                <td scope="col">{{calibrationRanges.version_major}}.{{calibrationRanges.version_minor}}</td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>

    <div class="row  justify-content-md-center">
      <div class="col-5">
        <h3>RANGES:</h3>
          <b-table :small="true" :striped="true"
                          :hover="true"
                          :items="ranges"
                          :fields="rangesFields">
          </b-table>
      </div>
    </div>

    <div class="row justify-content-md-center">
      <div class="col-2">
        <b-button :block="true" @click="$router.go(-1)">Back</b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      name: 'calibration-ranges-detail',
      ranges: [],
      calibrationRanges: {},
      showRaw: false,
      rangesFields:
      [
        { key: 'id', label: 'Operator' },
        { key: 'low', label: 'low' },
        { key: 'high', label: 'high' }
      ],
      props: ['id']
    }
  },
  name: 'calibration-ranges-detail',
  methods: {
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    fetchReport(id) {
      this.$http.get(`/calibration-ranges/${id}`).then(response => {
        this.calibrationRanges = response.data
        for (let range in this.calibrationRanges.ranges) {
          let obj = {
            id: range,
            low: this.calibrationRanges.ranges[range].low,
            high: this.calibrationRanges.ranges[range].high
          }
          this.ranges.push(obj)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  created() {
    this.fetchReport(this.$route.params.id)
  }
}
</script>
