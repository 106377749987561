<template>
  <div id="page-wrap">
    <div v-for="note in newNotifications" v-bind:key="note.id">
      <b-alert variant="info" show dismissible dismiss-label="Dismiss" v-on:dismissed="notificationDismissed(note.id)">
        <a :href="`/report-detail/${note.report_id}`" v-on:click="notificationDismissed(note.id)" class="alert-link">
          {{note.text}}
        </a>
      </b-alert>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-octicon/icons'
Vue.use(BootstrapVue)

export default {
  props: {
    authenticated: {
      type: Boolean,
      required: true,
      default: false
    },
    userId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data () {
    return {
      name: 'notification-alerts',
      newNotifications: []
    }
  },
  created() {
    if (this.authenticated) {
      return this.$http.get(`/notification`).then(response => {
        for (let note in response.data) {
          let notification = response.data[note]
          notification.text = `${notification.company_name} has performed
          ${notification.report_data.last_operation} on
          ${notification.report_data.end_serial} (${notification.created_at})`
          // Store new notifications in newNotifications array
          if (!notification.read_by.includes(this.userId)) {
            this.newNotifications.push(notification)
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  computed: {
  },
  methods: {
    notificationDismissed(id) {
      console.log('ACK', id)
      this.$http.put(`/notification`, { id: id }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
