<template>
    <div>
      <b-table ref="statisticsTable" :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :busy.sync="isBusy"
                      :striped="true"
                      :hover="true"
                      :items="fetchReports"
                      :fields="reportFields"
                      :outlined="true"
                      :small="true"
                      :filter="filter"
                      :current-page="currentPage"
                      :per-page="perPage">
        <template v-slot:cell(index)="data" label="test">
          {{data.item.id}}
        </template>
        <template v-slot:cell(end_serial)="data" label="test">
          <router-link :to="{name:'device-statistics', params:{end_serial:data.item.end_serial}}">{{data.item.end_serial}}</router-link>
        </template>
        <template v-slot:cell(production_date)="data" label="test">
          {{data.item.production_date | formatDate}}
        </template>
      </b-table>
      <div v-if="totalRows > perPage" class="row justify-content-md-center">
        <div class="col-6 mt-3">
          <b-pagination size="md" align="center" :total-rows="totalRows" v-model="currentPage" :per-page="perPage"></b-pagination>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Vue from 'vue'
//   import Octicon from 'vue-octicon/components/Octicon.vue'
  import 'vue-octicon/icons'
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  
  Vue.filter('formatDate', function(value) {
    // console.log(value)
    if (value) {
      return moment(String(value)).format('YYYY-MM-DD HH:mm')
    }
  })
  export default {
    props: [
      'filter',
    ],
    components: {
    //   Octicon
    },
    data () {
      return {
        name: 'statisitcs-list-table',
        sortBy: 'created_at',
        sortDesc: true,
        graphLoaded: false,
        currentPage: 1,
        perPage: 15,
        isBusy: false,
        totalRows: 0,
        // reports: [],
        reportFields:
        [
          { key: 'log', label: '#', sortable: false },
          { key: 'device_id', sortable: true },
          { key: 'end_serial', sortable: true },
          { key: 'production_date', label: 'Production Date', sortable: true },
          { key: 'ok_production', sortable: true },
          { key: 'nok_production', sortable: true },
          { key: 'ok_reset', sortable: true },
          { key: 'nok_reset', sortable: true },
          { key: 'created_at', label: 'Registration Date', sortable: true },
        ]
      }
    },
    created() {

    },
    computed: {
      ...mapGetters(['hasRole'])
    },
    methods: {
      fetchReports(ctx) {
        console.log("Current_page", ctx)
        this.isBusy = true
        return this.$http.get(`/measurementlogs`, {
          params: {
            ...ctx,
            device_id: this.device_id
          }
        }).then(response => {
          this.statistics = response.data.data
          this.totalRows = parseInt(response.data.total)
          this.isBusy = false
          return (this.statistics)
        }).catch(error => {
          console.log(error)
          // Here we could override the busy state, setting isBusy to false
          this.isBusy = false
          // Returning an empty array, allows table to correctly handle busy state in case of error
          return []
        })
      },

    }
  }
  </script>
  