<template>
 <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-5">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <div v-if="company" class="row  justify-content-md-center">
      <div class="col-5">
        <form class="ui form" @submit.prevent.stop>
          <div class="form-group">
            <label for="name">Company name</label>
            <input type="input" name="name" id="name" v-model.trim="$v.company.name.$model" :class="{ 'form-control': true, 'is-valid': !$v.company.name.$invalid, 'is-invalid': $v.company.name.$invalid && $v.company.name.$dirty }" aria-describedby="nameHelp" placeholder="Enter compnany name" required>
            <b-form-invalid-feedback>
              Please enter a name of at least 2 chars
            </b-form-invalid-feedback>
          </div>
          <div class="form-group">
            <input type="checkbox" name="manufacturer" v-model="company.manufacturer" required>
            Is manufacturer
            <b-form-invalid-feedback>
              Is this company manufacturer of Diagnoptics products or sub-assemblies?
            </b-form-invalid-feedback>
          </div>
          <button @click.prevent.stop="submit" type="submit" class="btn btn-primary">{{ buttonText }}</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: "CompanyForm",
  data() {
    return {
      company: {
        name: "",
        manufacturer: false
      },
      buttonText: "Create Company",
      title: "New company",
      submitAction: null,
      mode: "create"
    }
  },
  validations() {
    return {
      company: {
        name: {
          required,
          minLength: minLength(2)
        },
        manufacturer: {
          required
        }
      }
    }
  },
  methods: {
    fetchCompany(id) {
      this.$http.get(`/company/${id}`).then(response => {
        console.log(response)
        this.company = response.data
      })
    },
    submit() {
      // Update selected roles
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitAction()
      }
    },
    createCompany() {
      this.$http.post(`/company`, this.company).then(response => {
        console.log(response)
        this.$router.go(-1)
      }).catch((err) => {
        console.log(err.response)
      })
    },
    updateCompany() {
      this.$http.put(`/company/${this.company.id}`, this.company).then(response => {
        console.log(response)
        this.$router.go(-1)
      }).catch((err) => {
        console.log(err.response)
      })
    }
  },
  created() {
    console.log(this.$route.params.id)
    if (this.$route.params.id) {
      this.mode = "edit"
      console.log("EDIT mode")
      this.fetchCompany(this.$route.params.id)
      this.title = "Edit company"
      this.buttonText = "Update company"
      this.submitAction = this.updateCompany
    } else {
      this.mode = "create"
      this.submitAction = this.createCompany
    }
  }
}
</script>
