<template>
  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Calibration list</h2>
      </div>
    </div>
    <div>
      <div class="row justify-content-md-center mb-3">
        <div class="col-1"><label for="filter">Filter</label></div>
        <div class="col-4">
          <b-form-input v-model="filter"
                  type="text"
                  id="filter"
                  placeholder="e.g. FS, FLU"></b-form-input>
        </div>
        <div class="col-5"></div>
      </div>
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <b-table :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :striped="true"
                    :hover="true"
                    :items="fetchCalibrations"
                    :fields="calibrationFields"
                    :outlined="true"
                    :small="true"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    ref="calibration-table">
            <template v-slot:cell(selected)="data" label="selected">
              <b-form-checkbox @click.native.stop @change="clickedCheckbox" v-model="data.item.selected"></b-form-checkbox>
            </template>
            <template v-slot:cell(index)="data" label="test">
              {{data.item.id}}
            </template>
            <template v-slot:cell(calibration_serial)="data" label="test">
              <router-link :to="{name:'calibration-standard-detail', params:{id:data.item.id}}">
                {{data.item.calibration_serial}}-{{data.item.calibration_id | pad(3)}}
              </router-link>
            </template>
            <template v-slot:cell(calibration_date)="data" label="test">
              {{data.item.calibration_date | formatDate}}
            </template>
            <template v-slot:cell(expiry_date)="data" label="test">
              {{data.item.expiry_date | formatDate}}
            </template>
            <template v-slot:cell(approval)="data" label="released">
              <a v-if="!data.item.approved_by" href="#" @click.prevent.stop="releaseCalibration(data.item.id)">Approve calibration</a>
              <template v-else >Approved by {{data.item.approved_by_name}}</template>
            </template>
            <template v-slot:cell(download_certificate)="data" label="certificate">
              <div v-if="data.item.approved_by">
                <a href="#" @click.prevent.stop="downloadReport(data.item.id, data.item.calibration_serial, data.item.calibration_id, data.item.target_revision)"><octicon name="cloud-download"></octicon></a>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-6 mt-3">
        <b-pagination size="md" align="center" :total-rows="totalRows" v-model="currentPage" :per-page="perPage"></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import leftPad from 'left-pad'
import Octicon from 'vue-octicon/components/Octicon.vue'

Vue.filter('formatDate', function(value) {
  // console.log(value)
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
})

Vue.filter('pad', function(value, arg) {
  // console.log(value)
  if (value) {
    return leftPad(value, arg, '0')
  }
})
export default {
  components: {
    Octicon
  },
  data () {
    return {
      name: 'CalibrationList',
      sortBy: 'id',
      sortDesc: true,
      graphLoaded: false,
      isBusy: false,
      currentPage: 1,
      perPage: 15,
      filter: "",
      totalRows: 0,
      calibrations: [],
      calibrationFields:
      [
        { key: 'selected', label: '' },
        { key: 'index', label: '#' },
        { key: 'calibration_serial', sortable: true },
        { key: 'operator', sortable: true },
        { key: 'company', sortable: true },
        { key: 'calibration_date', sortable: true },
        { key: 'expiry_date', sortable: true },
        { key: 'description', sortable: true },
        { key: 'target_revision', sortable: true },
        // (() => {return this.$store.getters.hasRole('manager') || this.$store.getters.hasRole('administrator') ? 'approval' : null})()
        // <% if (me.role == "manager") { %>
        // 'approval',
        // <% } %>
        // <% if (me.role == "support" || me.role == "admin") { %>
        'download_certificate'
        // 'download_report'
        // <% } %>
      ]
    }
  },
  computed: {
    ...mapGetters(['hasRole'])
  },
  methods: {
    downloadReport(id, cal_serial, cal_id, target) {
      this.$http.get(`/calibration/${id}.pdf`, {
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${cal_serial}-${leftPad(cal_id, 3, '0')}_${target}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
    fetchCalibrations(ctx) {
      console.log("Current_page", ctx)
      this.isBusy = true
      return this.$http.get(`/calibration`, { params: ctx }).then(response => {
        console.log(response)
        this.calibrations = response.data.data
        this.totalRows = parseInt(response.data.total)
        this.isBusy = false
        return (this.calibrations)
      }).catch(error => {
        console.log(error)
        // Here we could override the busy state, setting isBusy to false
        this.isBusy = false
        // Returning an empty array, allows table to correctly handle busy state in case of error
        return []
      })
    },
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    clickedCheckbox(item, index, event) {
      console.log("Checkbox!", item, index)
      console.log("data!", this.reports)
      // window.location = "/report/"+item.id
    },
    releaseCalibration(id) {
      this.$http.put(`/calibration/${id}`).then(response => {
        this.fetchCalibrations()
        this.$refs['calibration-table'].refresh()
      })
    }
  },
  created() {
    // this.fetchCalibrations()
    if (this.hasRole('administrator')) {
      // Add columns based on roles
      this.calibrationFields.push('approval')
    }
  }
}
</script>
