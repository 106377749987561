<template>
  <div id="page-wrap">
    <b-navbar class="custom-shadow-red" fixed="top" toggleable="md" type="dark" variant="danger">
      <b-navbar-brand to="/"><b-img height="25" :src="image"></b-img> Diagnoptics Production Governor</b-navbar-brand>
      <b-collapse is-nav id="nav_collapse">
        <!-- LOGGED-IN NAVIGATION -->
        <b-navbar-nav class="ml-auto">
          <template v-if="isAuthenticated">
            <b-nav-item v-if="hasRole('administrator') || hasRole('support')" to="/statistics">Statistics</b-nav-item>
            <b-nav-item v-if="hasRole('administrator')" to="/company">Companies</b-nav-item>
            <b-nav-item v-if="hasRole('administrator')" to="/user">Users</b-nav-item>
            <b-nav-item v-if="hasRole('administrator')" to="/firmware">Firmware</b-nav-item>
            <b-nav-item v-if="hasRole('administrator') || hasRole('support')" to="/batches">Batches</b-nav-item>
            <b-nav-item v-if="hasRole('administrator') || hasRole('support') || hasRole('productionOwner')" to="/dhr">Device History Records</b-nav-item>
            <b-nav-item v-if="hasRole('administrator') || hasRole('support') || hasRole('operator') || hasRole('productionOwner')" to="/report">Reports</b-nav-item>
            <b-nav-item v-if="hasRole('administrator')" to="/calibration">Calibration Standards</b-nav-item>
            <b-nav-item v-if="hasRole('administrator')" to="/test-configs">HPS Configuration</b-nav-item>
            <b-nav-item v-if="hasRole('administrator')" to="/calibration-ranges">Calibration Ranges</b-nav-item>

            <!-- Notification history -->
            <b-nav-item-dropdown v-if="hasRole('administrator') || hasRole('support')" id="dropdown-right" right text="Right align" :no-caret="true" v-on:shown="getNotifications">
              <template v-slot:button-content>
                <octicon name="bell"></octicon>
              </template>
              <div class="row justify-content-md-center">
                <h4>Notifications:</h4>
              </div>
              <div v-for="note in notificationPage" v-bind:key="note.id">
                <b-list-group-item :href="`/report-detail/${note.report_id}`" style="width: 800px;" variant="light">
                  {{note.text}}
                </b-list-group-item>
              </div>
              <div class="row justify-content-md-center">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="notification-list"></b-pagination>
              </div>
            </b-nav-item-dropdown>

            <!-- Account -->
            <b-nav-item-dropdown style="text-transform: capitalize;" :text="getProfile('username')" right>
               <b-dropdown-item to="/account/me">Settings</b-dropdown-item>
                <b-dropdown-item @click="logout">Sign out</b-dropdown-item>
            </b-nav-item-dropdown>
          </template>
          <template v-else>
          <!-- LOGGED-OUT NAVIGATION -->
            <b-nav-item to="login">Login</b-nav-item>
          </template>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view></router-view>
    <footer id="page-footer" class="navbar navbar-light justify-content-between flex-row-reverse">
      <div class="nav">
        <small class="nav-item"><a class="nav-link text-info" href="/contact">Contact<span class="d-none d-sm-inline"> us</span></a></small>
        <small class="nav-item"><a class="nav-link text-info" href="/legal/terms">Terms<span class="d-none d-sm-inline"> of Use</span></a></small>
        <small class="nav-item"><a class="nav-link text-info" href="/legal/privacy">Privacy<span class="d-none d-sm-inline"> policy</span></a></small>
        <template v-if="isAuthenticated">
        <small class="nav-item">
          <a class="nav-link text-info" href="#" @click.prevent.stop="logout">Sign out</a></small>
        </template>
      </div>
      <small class="copy">Copyright &copy; {{year}} Diagnoptics. All rights reserved.</small>
    </footer>
  </div>
</template>

<script>
import logo from '@/assets/logo.png'
import { mapGetters } from 'vuex'
import { AUTH_LOGOUT } from '@/store/actions/auth'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import Octicon from 'vue-octicon/components/Octicon.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/custom.scss'

Vue.use(Vuelidate)
Vue.use(BootstrapVue)

export default {
  components: {
    Octicon
  },
  name: 'App',
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      image: logo,
      year: 2018,
      notifications: []
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'authStatus', 'hasRole', 'hasSignature', 'getProfile']),
    rows() {
      return this.notifications.length
    },
    notificationPage() {
      let currentPageStart = (this.currentPage - 1) * this.perPage
      return this.notifications.slice(currentPageStart, currentPageStart + this.perPage)
    }
  },
  methods: {
    getNotifications() {
      return this.$http.get(`/notification`).then(response => {
        this.notifications = []
        for (let note in response.data) {
          let notification = response.data[note]
          notification.text = `${notification.company_name} has performed
          ${notification.report_data.last_operation} on
          ${notification.report_data.end_serial} (${notification.created_at})`
          // Store all notifications in notifications array
          this.notifications.push(notification)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    logout () {
      const { email, password } = this
      this.$store.dispatch(AUTH_LOGOUT, { email, password }).then(() => {
        this.$router.push('/login')
      })
    }
  },
  created: async function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(() => {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        // if you ever get an unauthorized, logout the user
          this.$store.dispatch(AUTH_LOGOUT)
        // you can also redirect to /login if needed !
        }
        throw err
      })
    })
    this.year = new Date().getFullYear()
  }
}
</script>
