<template>
  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <notification-alert :authenticated="isAuthenticated && (hasRole('administrator') || hasRole('support'))" :userId="getProfile('id')"></notification-alert>
        <h2>{{title}}</h2>
      </div>
    </div>
    <div>
      <div class="row justify-content-md-center mb-3">
        <div class="col-1"><label for="filter">Filter</label></div>
        <div class="col-4">
          <b-form-input v-model="filter"
                  type="text"
                  id="filter"
                  placeholder="e.g. CFR, DMU"></b-form-input>
        </div>
        <div class="col-5"></div>
      </div>
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <report-list-table :filter="filter"></report-list-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import { REPORT_REQUEST } from '@/store/actions/reports'
import reportListTable from './ReportListTable'
import NotificationAlert from './../components/NotificationAlerts'

export default {
  data () {
    return {
      name: 'ReportList',
      props: ['device_serial'],
      filter: this.$route.params.device_serial ? this.$route.params.device_serial : "",
      title: this.$route.params.device_serial ? `Reports for: ${this.$route.params.device_serial}` : 'Reports'
    }
  },
  components: {
    reportListTable,
    NotificationAlert
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'authStatus', 'hasRole', 'hasSignature', 'getProfile'])
  },
  methods: {},
  created() {
    // this.$store.dispatch(REPORT_REQUEST)
  }
}
</script>
