<template>
  <div>
    <b-table  :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :busy.sync="isBusy"
                    :striped="true"
                    :hover="true"
                    :items="fetchDocuments"
                    :fields="documentFields"
                    :outlined="true"
                    :small="true"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage">
      <template v-slot:cell(selected)="data" label="selected">
        <b-form-checkbox @click.native.stop @change="clickedCheckbox" v-model="data.item.selected"></b-form-checkbox>
      </template>
      <template v-slot:cell(index)="data" label="test">
        {{data.item.id}}
      </template>
      <template v-slot:cell(download)="data" label="test">
         <a href="#" @click.prevent.stop="downloadDocument(data.item.id, data.item.name)"><octicon name="desktop-download"></octicon></a>
      </template>
    </b-table>
    <div v-if="totalRows > perPage" class="row justify-content-md-center">
      <div class="col-6 mt-3">
        <b-pagination size="md" align="center" :total-rows="totalRows" v-model="currentPage" :per-page="perPage"></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import Octicon from 'vue-octicon/components/Octicon.vue'
import { mapGetters } from 'vuex'

Vue.filter('formatDate', function(value) {
  // console.log(value)
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
})
export default {
  props: [
    'batch_id',
    'device_id',
    'filter'
  ],
  components: {
    Octicon
  },
  data () {
    return {
      name: 'document-list-table',
      sortBy: 'name',
      sortDesc: false,
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      totalRows: 0,
      // reports: [],
      documentFields:
      [
        { key: 'index', label: '#' },
        { key: 'name', sortable: true },
        { key: 'download' }
      ]
    }
  },
  computed: {
    ...mapGetters(['hasRole'])
  },
  methods: {
    fetchDocuments(ctx) {
      console.log("Current_page", ctx)
      this.isBusy = true
      return this.$http.get(`/document`, {
        params: {
          ...ctx,
          device_id: this.device_id,
          batch_id: this.batch_id
        }
      }).then(response => {
        console.log(response)
        this.documents = response.data.data
        this.totalRows = parseInt(response.data.total)
        this.isBusy = false
        return (this.documents)
      }).catch(error => {
        console.log(error)
        // Here we could override the busy state, setting isBusy to false
        this.isBusy = false
        // Returning an empty array, allows table to correctly handle busy state in case of error
        return []
      })
    },
    downloadDocument(id, name) {
      this.$http.get(`/document/${id}`, {
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    clickedCheckbox(item, index, event) {
      console.log("Checkbox!", item, index)
      console.log("data!", this.reports)
      // window.location = "/report/"+item.id
    }
  }
}
</script>
