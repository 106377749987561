<template>
  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <td class="col-1"><h2>Test Configuration</h2></td>
      </div>
    </div>

    <div v-if="testConfig">
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <table class="table table-sm table-bordered custom-shadow-grey">
            <thead>
              <tr>
                <th scope="col">End Type</th>
                <td scope="col">{{testConfig.end_type}}</td>
              </tr>
              <tr>
                <th scope="col">Base Type</th>
                <td scope="col">{{testConfig.base_type}}</td>
              </tr>
              <tr>
                <th scope="col">Test Configuration Version</th>
                <td scope="col">{{testConfig.version}}</td>
              </tr>
              <tr>
                <th scope="col">Created at</th>
                <td scope="col">{{testConfig.created_at}}</td>
              </tr>
              <tr>
                <th scope="col">Calibration Ranges Version</th>
                <td scope="col">{{testConfig.cal_ranges_version_major}}.{{testConfig.cal_ranges_version_minor}}</td>
              </tr>
              <tr>
                <th scope="col">Approved by</th>
                <td scope="col">
                  <div v-if="testConfig.approved_by">{{testConfig.approved_by_name}}</div>
                  <div v-else><a href="#">Approve</a></div>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>

    <div class="row  justify-content-md-center">
      <div class="col-10"  v-for="test in testsWithRules" :key="test.id">
        <h3>Test ID: {{test.report_id}}</h3>
        <b-table :small="true" :striped="true"
                        :hover="true"
                        :items="test.parsedRules"
                        :fields="mainFields">
        </b-table>
      </div>
    </div>

    <div class="row justify-content-md-center mb-3" v-if="showRawConfig">
      <div class="col-10">
        <b-card class="mt-3" header="Raw Test Config">
          <code><pre class="m-0">{{ testConfig }}</pre></code>
        </b-card>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-2">
        <b-button :block="true" @click="$router.go(-1)">Back</b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      name: 'test-config-detail',
      testConfig: {},
      testList: [],
      testsWithRules: [],
      showRawConfig: false,
      mainFields:
      [
        { key: 'description', label: 'Parameter' },
        { key: 'ruleOperator', label: 'Operator' },
        { key: 'min', label: '' },
        { key: 'max', label: '' }
      ],
      props: ['id']
    }
  },
  name: 'test-config-viewer',
  methods: {
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    fetchReport(id) {
      this.$http.get(`/test-configuration?id=${id}`).then(response => {
        this.testConfig = response.data
        let testsWithRules = []

        // Find all tests with rules and put them into an array
        for (let test in this.testConfig.tests) {
          if (Object.prototype.hasOwnProperty.call(this.testConfig.tests, test)) {
            let testObj = this.testConfig.tests[test]
            if (Object.prototype.hasOwnProperty.call(testObj, 'rules') && (testObj['rules'].length > 0)) {
              testsWithRules.push(testObj)
            }
          }
        }

        // For each test with rules: parse the rules into a format that is suitable for tables
        testsWithRules.map((test) => {
          test.parsedRules = []
          test['rules'].map((rule) => {
            let ruleValue = rule.expected
            let operator = rule.type
            let obj = {
              description: rule.label,
              ruleOperator: rule.type,
              min: null,
              max: null
            }

            switch (operator) {
              case ">":
              case ">=":
              case "<=":
              case "==":
              case "<": {
                obj.min = ruleValue
                obj.max = null
                break
              }
              case "within": {
                obj.min = `${ruleValue.percentage}%`
                obj.max = 'of ' + ruleValue.of
                break
              }
              case "range":
              case "between":
              case "between_or_equal": {
                obj.min = ruleValue.min
                obj.max = ruleValue.max
                break
              }
              case "dynamic": {
                obj.min = 'compare to'
                obj.max = rule.dynamic
                break
              }
              case "calibration_range" : {
                obj.ruleOperator = 'calibration parameter'
                obj.min = rule.expected.id
                obj.max = ''
                break
              }
              default: break
            }
            test.parsedRules.push(obj)
          })
        })
        this.testsWithRules = testsWithRules
        console.log(testsWithRules)
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  created() {
    this.fetchReport(this.$route.params.id)
  }
}
</script>
