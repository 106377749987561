<template>

  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Calibration Ranges</h2>
      </div>
    </div>
    <div>
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <b-table :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :busy.sync="isBusy"
                          :striped="true"
                          :hover="true"
                          :items="fetchCalibrationRanges"
                          :fields="listFields"
                          :outlined="true"
                          :small="true"
                          :filter="filter"
                          :current-page="currentPage"
                          :per-page="perPage"
                          ref="calibration-range-list-table">
            <template v-slot:cell(revision)="data">
              <router-link :to="{name:'calibration-ranges-detail', params:{id:data.item.id}}">{{data.item.revision}}</router-link>
            </template>
          </b-table>
          <div v-if="!device_id" class="row justify-content-md-center">
            <div class="col-6 mt-3">
              <b-pagination size="md" align="center" :total-rows="totalRows" v-model="currentPage" :per-page="perPage"></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-octicon/icons'
import moment from 'moment'
import { mapGetters } from 'vuex'
Vue.use(BootstrapVue)

Vue.filter('formatDate', function(value) {
  // console.log(value)
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
})
export default {
  props: [
    'device_id',
    'filter'
  ],
  data () {
    return {
      name: 'calibration-range-list',
      sortBy: 'id',
      sortDesc: true,
      currentPage: 1,
      perPage: 10,
      isBusy: false,
      totalRows: 0,
      listFields:
      [
        { key: 'revision', label: 'Hardware Revision' },
        { key: 'version_major', label: 'Version Major' },
        { key: 'version_minor', label: 'Version minor' },
        { key: 'created_at', label: 'Created at' }
      ]
    }
  },
  computed: {
    ...mapGetters(['hasRole'])
  },
  methods: {
    fetchCalibrationRanges() {
      this.isBusy = true
      return this.$http.get(`/calibration-ranges`).then(response => {
        this.isBusy = false
        return response.data
      }).catch(error => {
        console.log(error)
        // Here we could override the busy state, setting isBusy to false
        this.isBusy = false
        // Returning an empty array, allows table to correctly handle busy state in case of error
        return []
      })
    },
    approveCalibrationRanges(configId) {
      this.$http.put(`/calibration-ranges`, {
        id: configId,
        approve: true
      }).then(response => {
        this.fetchCalibrationRanges()
        this.$refs['calibration-range-list-table'].refresh()
      })
    }
  }
}
</script>
