<template>
 <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Edit your account settings</h2>
      </div>
    </div>
    <div v-if="user" class="row  justify-content-md-center">
      <div class="col-10">
        <b-alert :show="dismissCountDown"
             dismissible
             :variant="notification.type"
             @dismissed="dismissCountDown=0">
             {{ notification.message }}
        </b-alert>
        <form class="ui form" @submit.prevent.stop>
          <div class="form-group">
            <label for="username">Username</label>
            <input readonly type="input" name="username" v-model="user.username" class="form-control" aria-describedby="usernameHelp" placeholder="Enter username" required>
          </div>
          <div class="form-group">
            <label for="email">Email address</label>
            <input readonly type="email" name="email" v-model="user.email" class="form-control" aria-describedby="emailHelp" placeholder="Enter email" required>
          </div>
          <div class="form-group">
            <label>Company: </label>
            {{ user.company.name }}
          </div>
          <div class="form-group">
            <label>Password</label>
            <input type="password" class="form-control" name="password" v-model="user.password" placeholder="Password" autocomplete="password">
          </div>
          <div class="form-group">
            <label>Repeat password</label>
            <input type="password" class="form-control" name="passwordRepeat" v-model="user.passwordRepeat" placeholder="Repeat password" autocomplete="password">
          </div>

           <b-btn @click.prevent.stop="updateUser" variant="primary">Save</b-btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { USER_SUCCESS } from '@/store/actions/auth'

export default {
  name: "UserAccountEdit",
  data() {
    return {
      user: {
        password: "",
        passwordRepeat: "",
        roles: [],
        company: {
          name: ""
        }
      },
      signatureFile: {
        name: ""
      },
      dismissCountDown: 0,
      dismissSecs: 8,
      notification: {
        message: '',
        type: ''
      },
      user_roles: [],
      roles: [],
      selected_roles: [],
      companies: [],
      selected_company: []
    }
  },
  methods: {
    fetchUser() {
      this.$http.get(`/account/me`).then(response => {
        console.log(response)
        this.user = response.data.data
        this.user.password = ''
        this.user.passwordRepeat = ''
        this.selected_roles = this.user.roles.map((r) => r.id)
        this.selected_company = this.user.company_id // .map((r) => r.id)
        localStorage.setItem('user', JSON.stringify(response.data.data))
        this.$store.commit(USER_SUCCESS, response.data.data)
      })
    },
    fetchRoles() {
      this.$http.get(`/roles`).then(response => {
        console.log(response)
        this.roles = response.data.map((r) => { return { value: r.id, text: r.name } })
      })
    },
    fetchCompanies() {
      this.$http.get(`/companies`).then(response => {
        console.log(response)
        this.companies = response.data.map((r) => { return { value: r.id, text: r.name } })
      })
    },
    uploadSignature() {
      const formData = new FormData()

      if (!this.signatureFile) return
      // append the files to FormData
      console.log("Adding signature", this.signatureFile)
      formData.append('signature', this.signatureFile, this.signatureFile.name)
      console.log("FormData", formData)

      this.$http.post(`/account/me/signature`, formData).then(() => {
        this.$refs.fileinput.reset()
        this.signatureFile = {
          name: ""
        }
        this.fetchUser()
      })
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    updateUser() {
      this.$http.put(`/account/me`, this.user).then(response => {
        console.log(response)
        this.$router.go(-1)
      }).catch((err) => {
        console.log(err.response)
        this.notification = {
          message: err.response.data.message,
          type: 'danger'
        }
        this.showAlert()
      })
    }
  },
  created() {
    this.fetchUser()
  }
}
</script>
