<template>
  <div class="container-fluid">
    <help-card :topic="'batch'"></help-card>
    <div class="row  justify-content-md-center">
      <div class="col-10">
        <h2>Batches</h2>
      </div>
    </div>
    <div v-if="batches">
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <b-table :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :busy.sync="isBusy"
                    :striped="true"
                    :hover="true"
                    :items="fetchBatches"
                    :fields="reportFields"
                    :outlined="true"
                    :small="true"
                    :current-page="currentPage"
                    :per-page="perPage"
                    ref="batchTable">
            <template v-slot:cell(selected)="data" label="selected">
                <b-form-checkbox @click.native.stop @change="clickedCheckbox" v-model="data.item.selected">
                  </b-form-checkbox>
              </template>
            <template v-slot:cell(index)="data" label="test">
              {{data.index}}
            </template>
            <template v-slot:cell(number_of_devices)="data" label="test">
              {{ data.item.__meta__.devices_count }}
            </template>
            <template v-slot:cell(batch_id)="data" label="result">
              {{data.item.batch_id || "Not assigned"}}
            </template>
            <template v-slot:cell(released_by)="data" label="result">
              {{data.item.username}}
            </template>
            <template v-slot:cell(details)="row">
              <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
              <a href="#" @click.stop="row.toggleDetails">
                {{row.detailsShowing ? 'Hide' : 'Show'}}
                <octicon :name="row.detailsShowing ? 'chevron-up' : 'chevron-down'"></octicon>
              </a>
            </template>
            <template v-slot:cell(reports)="row">
              <a href="#" @click.prevent.stop="getReportsForBatch(row.item, 'report')"><octicon name="desktop-download"></octicon></a>
            </template>
            <template v-slot:cell(certificates)="row">
              <div v-if="row.item.devices[0].revision === 'CFR00101a'">
                <a href="#" @click.prevent.stop="getReportsForBatch(row.item, 'certificate')"><octicon name="desktop-download"></octicon></a>
              </div>
            </template>
            <template v-slot:cell(upload)="row">
              <a href="#" @click.prevent.stop v-b-modal="'uploadBox'+row.item.id">
                Add
              </a>
                <!-- Modal Component -->
              <b-modal @ok="uploadDocument(row.item.id)" :id="'uploadBox'+row.item.id" title="Select document to upload">
                <div class="mb-3">
                  Choose the documents you want to add to {{row.item.name}}
                </div>
                <b-form-file multiple v-model="fileList" ref="fileinput" placeholder="Choose a file..."></b-form-file>
                <div class="mt-3">Selected file(s): {{fileList && cutString(fileList.map(f=>f.name).join(', '))}}</div>
              </b-modal>
            </template>
            <template slot="row-details" slot-scope="row">
              <b-card class="custom-shadow-grey rounded-0" border-variant="dark" bg-variant="white">
                <strong slot="header">{{row.item.name}}</strong>
                <template v-if="row.item.documents.length > 0" >
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-left"><b>Documents for this batch:</b></b-col>
                  </b-row>
                  <document-list-table :batch_id="row.item.id"></document-list-table>
                </template>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-left"><b>Devices in this batch:</b></b-col>
                </b-row>
                <b-row v-bind:key="device.id" v-for="device in row.item.devices" class="mb-2">
                  <b-col sm="3">{{ device.end_serial }}</b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
            <b-button :disabled="!selected_items" size="sm" @click.stop="downloadBatch()" class="mr-2">
              Download SAP Export file
            </b-button>
        </div>
      </div>
    </div>
    <div v-if="totalRows > perPage" class="row justify-content-md-center">
      <div class="col-6">
        <b-pagination size="md" align="center" :total-rows="totalRows" v-model="currentPage" :per-page="perPage"></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import Octicon from 'vue-octicon/components/Octicon.vue'
import documentListTable from './DocumentListTable'
import HelpCard from './../components/HelpCard'

Vue.filter('formatDate', function(value) {
  // console.log(value)
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
})
export default {
  name: 'batch-list',
  components: {
    Octicon,
    documentListTable,
    HelpCard
  },
  data () {
    return {
      sortBy: 'id',
      sortDesc: true,
      isBusy: false,
      currentPage: 1,
      perPage: 15,
      filter: "",
      totalRows: 0,
      graphLoaded: false,
      batches: [],
      batch_id: null,
      fileList: [],
      reportFields:
      [
        { key: 'selected', label: '' },
        { key: 'id', label: '#' },
        { key: 'name', sortable: true },
        { key: 'number_of_devices', sortable: true },
        { key: 'download', label: '', sortable: false },
        { key: 'reports', label: 'Production Reports', sortable: false },
        { key: 'certificates', label: 'Certificates', sortable: false },
        { key: 'upload', label: 'Upload files', sortable: false },
        { key: 'details', label: 'Details', sortable: false }
      ]
    }
  },
  computed: {
    selected_items() {
      return this.batches.some(r => r.selected)
    }
  },
  methods: {
    cutString(text) {
      let wordsToCut = 2
      var wordsArray = text.split(" ")
      if (wordsArray.length > wordsToCut) {
        var strShort = ""
        for (let i = 0; i < wordsToCut; i++) {
          strShort += wordsArray[i] + " "
        }
        return strShort + "..."
      } else {
        return text
      }
    },
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    clickedRow(item, index, event) {
      console.log("Clicked!", item)
      window.location = "/report/" + item.id
    },
    clickedCheckbox(item, index, event) {
      console.log("Checkbox!", item, index)
      console.log("data!", this.devices)
      // window.location = "/report/"+item.id
    },
    getFinalTestResult(tests) {
      // Check if there is a fail in one of the tests WARNING do not test for Pass since there can be retries
      return tests.every(test => { return test.result.toUpperCase() != "FAIL" }) ? "PASS" : "FAIL"
    },
    fetchBatches(ctx) {
      console.log("Current_page", ctx)
      this.isBusy = true
      return this.$http.get(`/batches`, {
        params: {
          ...ctx
        }
      }).then(response => {
        console.log(response)
        this.batches = response.data.data
        this.totalRows = parseInt(response.data.total)
        this.isBusy = false
        return (this.batches)
      }).catch(error => {
        console.log(error)
        // Here we could override the busy state, setting isBusy to false
        this.isBusy = false
        // Returning an empty array, allows table to correctly handle busy state in case of error
        return []
      })
    },
    getReportsForBatch(batch, type) {
      console.log(batch)
      this.$http.get(`/reports-for-batch`, {
        params: {
          batch_id: batch.id
        }
      }).then(async reports => {
        console.log('REPORTS FOR BATCH', reports.data)
        for (let i in reports.data) {
          let report = reports.data[i]
          let name = report.end_serial
          let id = report.id
          console.log('REPORT:', name, id, type)
          await this.$http.get(`/report/${type}/${id}.pdf`, {
            responseType: 'blob'
          }).then((response) => {
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${name}_${type}.pdf`) // or any other extension
            document.body.appendChild(link)
            link.click()
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    downloadBatch() {
      this.$http.get(`/sap`, {
        params: {
          devices: this.batches.filter(r => r.selected).map(r => r.id)
        }
      }).then(response => {
        console.log(response.data)
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data, null, 2)]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `EXPORT_${moment().format("YYYYMMDD_HHmm")}_BATCH_RESULTS.json`) // or any other extension
        document.body.appendChild(link)
        link.click()
        // this.batches = response.data
      })
    },
    uploadDocument(id) {
      // this.isBusy = true
      const formData = new FormData()
      let sizeError = false

      if (!this.fileList.length) return
      // append the files to FormData
      this.fileList.forEach(x => {
        console.log("Adding document", x)
        formData.append('documents[]', x, x.name)
        // 5mb file size limit
        if (x.size > 5000000) {
          sizeError = true
        }
      })

      if (sizeError) {
        console.log('File size limitation exceeded. Upload canceled!')
        return
      }
      console.log("FormData", formData)

      this.$http.post(`/batches/${id}/upload`, formData).then((response) => {
        this.$refs.fileinput.reset()
        this.$refs.batchTable.refresh()
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
