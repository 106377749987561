<template>
  <div class="container-fluid">
    <div class="row  justify-content-md-center">
      <div class="col-6">
        <td class="col-1"><h2>Report Viewer</h2></td>
      </div>
      <div class="col-4 align-self-end">
        <div class="float-right">
          <a  v-if="showCertifcateDownload" 
              href="#" 
            >
          </a>
          <button 
            v-if="showCertifcateDownload" 
            type="button" 
            class="btn btn-link" 
            @click.prevent.stop="fetchCertifcate()"
            >
            Download certificate <octicon name="desktop-download"></octicon>
          </button>

        </div>
      </div>
    </div>
    <div v-if="report">
      <div class="row  justify-content-md-center">
        <div class="col-10">
          <caption><h3>1.&nbsp;General&nbsp;information</h3></caption>
          <table class="table table-sm table-bordered custom-shadow-grey">
            <thead>
              <tr>
                <th scope="col">Production date</th>
                <td scope="col">{{report.production_date | formatDate}}</td>
                <th scope="col">Operator</th>
                <td scope="col">{{report.user.username}}</td>
              </tr>
              <tr>
                <th scope="col">Duration</th>
                <td scope="col">{{report.duration}}</td>
                <th scope="col">Hardware revision</th>
                <td scope="col">{{report.revision}}</td>
              </tr>
              <tr>
                <th scope="col">End Serial</th>
                <td scope="col">{{report.end_serial}}</td>
                <th scope="col">Base Serial</th>
                <td scope="col">{{report.base_serial}}</td>
              </tr>
              <tr>
                <th scope="col">Bootloader version</th>
                <td scope="col">{{report.bootloader_version}}</td>
                <th scope="col">Firmware version</th>
                <td scope="col">{{report.firmware_version}}</td>
              </tr>
              <tr>
                <th scope="col">Test type</th>
                <td scope="col">{{report.testType}}</td>
                <th scope="col">HPS version</th>
                <td scope="col">{{report.version}}</td>
              </tr>
              <tr v-if="report.calibration_standard">
                <th scope="col">Calibration Standard</th>
                <td scope="col">{{report.calibration_standard}}</td>
                <th scope="col">Calibration Ranges Version</th>
                <td scope="col">{{report.cal_ranges_version_major}}.{{report.cal_ranges_version_minor}}</td>
              </tr>
              <tr>
                <th scope="col">Test Configuration Version</th>
                <td scope="col" colspan=3>{{report.configuration_version}}</td>
              </tr>
            </thead>
            <tbody v-if="report">
            </tbody>
          </table>
        </div>
      </div>

      <div class="row  justify-content-md-center">
        <div class="col-10">
          <b-table
            :items="report.testResults"
            :fields="reportFields"
            :small="true"
            :outlined="true"
            caption-top>
            <template slot="table-caption">
              <h3>2. Test results</h3>
            </template>
            <template v-slot:cell(index)="data" labe="test">
              {{data.index}}
            </template>
            <template v-slot:cell(show_details)="row">
              <a href="#" @click.stop="row.toggleDetails" v-if="row.item.rules[0] || row.item.errorMessage">
                <octicon :name="'triangle-' + (row.detailsShowing ? 'up' : 'down')"></octicon>
              </a>
            </template>
            <template slot="row-details" slot-scope="row">
                <div class="col-12" v-if="graphLoaded && row.item.test_id === 'test_geocheck_device'">
                  <div class="row justify-content-md-center">
                    <div class="col-sm-6">
                      <geograph :elementId="geoGraphData[0].id" :title="geoGraphData[0].title" :labels="geoGraphData[0].labels" :values="geoGraphData[0].values" :leftBorder="geoGraphData[0].leftBorder" :rightBorder="geoGraphData[0].rightBorder"></geograph>
                    </div>
                    <div class="col-sm-6">
                      <geograph :elementId="geoGraphData[1].id" :title="geoGraphData[1].title" :labels="geoGraphData[1].labels" :values="geoGraphData[1].values" :leftBorder="geoGraphData[1].leftBorder" :rightBorder="geoGraphData[1].rightBorder"></geograph>
                    </div>
                  </div>
                </div>
                <b-table
                  :items="row.item.rules"
                  :outlined="true"
                  :small="true"
                  :fields="testRuleFields" v-if="row.item.rules.length > 0">
                  <template v-slot:cell(testAgainst)="row">
                    <div v-if="row.item.max">{{row.item.min}} - {{row.item.max}}</div>
                    <div v-else-if="row.item.percent">{{row.item.percent}}% of {{roundToFour(row.item.of)}}</div>
                    <div v-else>{{roundToFour(row.item.testValue)}}</div>
                  </template>
                  <template v-slot:cell(result)="row">
                    {{row.item.result === true ? 'Pass' : 'Fail'}}
                  </template>
                </b-table>
                <div class="col-12" v-if="row.item.failureCauses">
                  <div class="row">
                    <h4>Probable failure causes</h4>
                    <b-table :items="row.item.failureCauses" :fields="[{'key': 'title', 'label': 'Cause'}, 'description', 'resolution']">
                    </b-table>
                  </div>
                </div>
                <div class="col-12" v-if="row.item.errorMessage">
                  <div class="row">
                    {{row.item.errorMessage}}
                  </div>
                </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center" v-if="report">
      <div class="col-2">
        <b-button :block="true" @click="$router.go(-1)">Back</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import geograph from '@/components/GeoGraph'
import Octicon from 'vue-octicon/components/Octicon.vue'
import { mapGetters } from 'vuex'

Vue.filter('formatDate', function(value) {
  // console.log(value)
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD HH:mm')
  }
})
export default {
  data () {
    return {
      name: 'ReportListDetail',
      props: ['id'],
      graphLoaded: false,
      report: {
        user: {
          username: ""
        },
        testResult: []
      },
      reportFields: [
        { key: 'index', label: '#' },
        'description',
        { key: 'show_details', label: "" },
        { key: 'result', 'formatter': this.cellColorTextFormatter }
      ],
      testRuleFields: [
        { key: 'label', label: 'Label' },
        { key: 'value', label: 'Value', formatter: this.roundToFour },
        { key: 'operator', label: 'Rule' },
        { key: 'testAgainst', 'label': 'Expected Value' },
        { key: 'result', 'formatter': this.cellColorFormatter }
      ],
      geoGraphData: []
    }
  },
  name: 'report-viewer',
  components: { geograph, Octicon },
  computed: {
    ...mapGetters(['hasRole']),
    showCertifcateDownload() {
      if(!this.report){
        return false
      }

      // report.revision == CFR00101a or MFR00100
      const allowed_revisions = [
        'CFR00101a',
        'MFR00100'
      ]

      if(!allowed_revisions.includes(this.report.revision)){
        return false
      }

      if(this.report.result !== 'Pass'){
        return false
      }

      const allowed_tests = [
        "PROGRAM_AND_CALIBRATE",
        "CALIBRATION_VERIFY_FSS",
        "CALIBRATION_FSS"
      ]

      // check if report.testType is in allowed_tests
      if(!allowed_tests.includes(this.report.testType)){
        console.log('Test type is not in allowed tests')
        return false
      }


      const allowed_roles = [
        "distributor",
        "administrator",
        "support",
      ]
      
      let hasAnyRole = false;
      // check if user has allowed role

      for(let role of allowed_roles){
        if(this.hasRole(role)){
          hasAnyRole = true
          break
        }
      }

      if(!hasAnyRole){
        console.log('User does not have any of the allowed roles')
        return false
      }

      // more logic here
      return true;
    },
  },
  methods: {
    fetchCertifcate(){
      const url = "/report/" + this.report.id + "/certificate"
      
      this.$http.get(url, {
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const date = moment(String(this.report.production_date)).format('YYYY-MM-DD HH:mm')
        // TODO: filename
        const filename = `${this.report.end_serial}_certifcate_of_inspection_${date}.pdf`
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
    roundToFour(num) {
      let roundNum = +(Math.round(num + "e+4") + "e-4")
      return isNaN(roundNum) ? num : roundNum
    },
    cellColorFormatter(value, key, item) {
      if (value === true) {
        item._rowVariant = 'success'
      } else {
        item._rowVariant = 'danger'
      }
      return value
    },
    fetchReport(id) {
      this.$http.get(`/report/${id}`).then(response => {
        console.log(response)
        this.report = response.data
        this.updateData()
      })
    },
    updateData() {
      this.report.testResults.forEach((test) => {
        test._cellVariants = {
          result: {
            'Pass': 'success',
            'Fail': 'danger',
            'Retry': 'warning'
          }[test.result]
        }
        if (test.test_id == "test_geocheck_device") {
          if (test.data.x) {
            console.log('test', test)
            let x_roundedPositions = test.data.x.position
            let y_roundedPositions = test.data.y.position

            this.geoGraphData = [
              {
                id: 'geo-chart-x',
                title: 'Geocheck X',
                values: test.data.x.value,
                labels: x_roundedPositions,
                leftBorder: test.data.x.aoi_left,
                rightBorder: test.data.x.aoi_right
              },
              {
                id: 'geo-chart-y',
                title: 'Geocheck Y',
                values: test.data.y.value,
                labels: y_roundedPositions,
                leftBorder: test.data.y.aoi_left,
                rightBorder: test.data.y.aoi_right
              }
            ]
            this.graphLoaded = true
          }
        }
      })
    }
  },
  created() {
    this.fetchReport(this.$route.params.id)
  }
}
</script>
